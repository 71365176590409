<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectCustomer
                :label="$t('labels.customer')"
                :placeholder="$t('labels.customer')"
                name="id_customer"
                sort-name="company_name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.session_code')"
                :placeholder="$t('labels.session_code')"
                name="tracking_id"
                sort-name="tracking_id"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.created_at')"
                :placeholder="$t('labels.created_at')"
                name="create_time"
                sort-name="create_time"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilterFromTo
                :label="$t('labels.request_quantity')"
                :placeholder="$t('labels.request_quantity')"
                name="request_quantity"
                sort-name="request_quantity"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilterFromTo
                :label="$t('labels.receipt_quantity')"
                :placeholder="$t('labels.receipt_quantity')"
                name="total_receipt_quantity"
                sort-name="total_receipt_quantity"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilterFromTo
                :label="$t('labels.sub_quantity')"
                :placeholder="$t('labels.sub_quantity')"
                name="sub_quantity"
                sort-name="sub_quantity"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.time')"
                :placeholder="$t('labels.time')"
                name="finish_time"
                sort-name="finish_time"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="text-center">
            <td>
              <div style="font-size: 18px">{{ item.warehouse_code }}</div>
            </td>
            <td>{{ item.company_name }}</td>
            <td>
              <div style="font-size: 18px">{{ item.tracking_id }}</div>
            </td>
            <td>
              {{ item.create_time ? formatDateTime(item.create_time) : "" }}
            </td>
            <td>
              <span class="text-h4 success--text">{{
                item.total_request_quantity
              }}</span>
            </td>
            <td>
              <span class="text-h4 info--text">{{
                item.total_receipt_quantity
              }}</span>
            </td>
            <td>
              <span class="text-h4 error--text">{{ item.sub_quantity }}</span>
            </td>
            <td>
              {{ item.finish_time ? formatDateTime(item.finish_time) : "" }}
            </td>
            <td>
              <v-btn small color="primary" @click="downloadRecord(item)"
                ><v-icon>mdi-download</v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="pt-3">
      <v-col cols="12" sm="1"></v-col>
      <v-col cols="12" sm="10" class="text-right">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="12" sm="1">
        <div class="d-flex">
          <v-text-field
            class="c-input-xs mr-2"
            dense
            outlined
            hide-details
            v-model.number="page"
            :label="$t('labels.page')"
            :placeholder="$t('labels.page')"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  debounce,
  formatDateTime,
  downloadPdfFile,
  downloadExcelFile,
} from "@/libs/helpers";
import { httpClient } from "@/libs/http";
import { IMAGE_OPTIONS } from "@/libs/const";

export default {
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectCustomer: () => import("@/components/table/SelectCustomer"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
  },
  name: "Index",
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    updatingItem: {},
    filters: {},
    sort: {},
    imageOptions: [...IMAGE_OPTIONS],
    checkDialog: false,
    isLoading: false,
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    formatDateTime,
    downloadPdfFile,
    downloadExcelFile,
    getList: debounce(function () {
      httpClient
        .post("/goods-receipt/v1/list-record", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 500),
    downloadRecord(item) {
      const fileName = `${item.company_name}_${item.tracking_id}_bien_ban_nhap_kho.xlsx`;
      this.downloadExcelFile(
        "/goods-receipt/v1/download-record",
        {
          id_goods_receipt: item.id,
          id_warehouse: item.id_warehouse,
        },
        fileName
      )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped></style>
